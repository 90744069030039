import React, { useMemo, useState } from 'react';
import {
  AgxButton,
  AgxRow,
  Images,
  AgxColumn,
  AgxBodyText,
  AgxHeader,
  AgxSlideUpModal,
  DocumentTypes,
  AgxDivider,
  DocumentTypesMap,
  AgxMultiOptionButton,
  CampaignDetailModel,
} from '@urbanx/agx-ui-components';
import { MultiOptionButtonOption } from '@urbanx/agx-ui-components/dist/cjs/types/components/actions/MultiOptionButton/MultiOptionButton';
import { useGetFileLink } from 'components/ui-components/File/fileApi';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useFormLoader } from 'hooks/useFormLoader';
import { Tabs } from 'types/Tabs';
import { setAndShowErrorToast } from 'store/config';
import { removeFormSubmission } from '../../../../../../campaigns/campaignsReducer';
import useStateFormLabels from '../../../../../../form/FormLabels/useStateFormLabels';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import './SentForSigningButtons.scss';
import { sortDocumentsByType } from 'utils/documentUtil';

interface SentForSigningButtonsProps {
  campaign: CampaignDetailModel;
  envelopeId: string;
  currentTab: Tabs;
  isMobile?: boolean;
  isDesktop?: boolean;
}

export const SentForSigningButtons: React.FC<SentForSigningButtonsProps> = ({
  campaign,
  envelopeId,
  currentTab,
  isMobile = false,
  isDesktop = false,
}) => {
  const [, getAuthToken] = useAzureAuth();
  const dispatch = useAppDispatch();
  const { loadForm } = useFormLoader();
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);
  const { address, completionState, campaignId, documents } = campaign;
  const formName = useStateFormLabels(address.state, 'formName');
  const getFileLink = useGetFileLink();

  const noAttachments = useMemo(() => {
    const documentTypes = documents?.map(doc => doc.documentType);
    const uniqueTypes = new Set(documentTypes);
    return (
      uniqueTypes.size === 2 &&
      uniqueTypes.has(DocumentTypes.AgencyAgreement) &&
      uniqueTypes.has(DocumentTypes.MergedAgencyAgreement)
    );
  }, [documents]);

  const fileDownloadOptions = useMemo(() => {
    if (!documents || documents.length === 0) return [];
    const options: MultiOptionButtonOption[] = [];
    const miscDocumentTypes = [
      DocumentTypes.Miscellaneous,
      DocumentTypes.AdditionalAnnexure,
    ];

    const orderedDocuments = sortDocumentsByType(documents);

    const nonMiscDocuments = orderedDocuments.filter(
      (d, ind, arr) =>
        !miscDocumentTypes.includes(d.documentType) &&
        (!ind || d.documentType !== arr[ind - 1]?.documentType)
    );
    const miscDocuments = orderedDocuments.filter(d =>
      miscDocumentTypes.includes(d.documentType)
    );

    nonMiscDocuments.forEach(d => {
      options.push({
        text: DocumentTypesMap[d.documentType],
        onClick: async () => {
          const fileLink = await getFileLink(d.containerFilePath);
          if (fileLink != null) {
            openFileInNewTab(isDesktop, fileLink);
          }
        },
      });
    });

    miscDocuments.forEach(d => {
      options.push({
        text: d.fileName,
        onClick: async () => {
          const fileLink = await getFileLink(d.containerFilePath);
          if (fileLink != null) {
            openFileInNewTab(isDesktop, fileLink);
          }
        },
      });
    });

    return options;
  }, [documents, getFileLink]);

  const onEditAgencyAgreementClick = () => {
    setShowConfirmationModel(true);
  };

  const onConfirmEdit = async () => {
    if (!campaignId || !envelopeId) return;

    const inProgressForm =
      campaign.inProgressForms != null ? campaign.inProgressForms[0] : null;

    if (inProgressForm != null) {
      try {
        setDisableInputs(true);
        const authToken = await getAuthToken();

        if (!authToken) {
          setDisableInputs(false);
          return;
        }

        await removeFormSubmission(authToken, campaignId, envelopeId);

        await loadForm(campaignId, inProgressForm.formId);
      } catch (err) {
        console.error(err);
        dispatch(setAndShowErrorToast('Failed to void envelope'));
      }

      setDisableInputs(false);
    }
  };

  const ViewDocumentsButton = () => {
    if (fileDownloadOptions.length === 0) return <></>;

    return fileDownloadOptions.length === 1 || noAttachments ? (
      <AgxButton
        id="downloadContract"
        text="Download"
        hollow
        disabled={currentTab === Tabs.Archive}
        large={!isMobile}
        medium={isMobile}
        rightIcon={<Images.Download />}
        wide={isMobile}
        onClick={fileDownloadOptions[0].onClick}
      />
    ) : (
      <AgxMultiOptionButton
        id="downloadContracts"
        text="Download"
        hollow
        disabled={currentTab === Tabs.Archive}
        large={!isMobile}
        medium={isMobile}
        rightIcon={<Images.Download />}
        wide={isMobile}
        options={fileDownloadOptions}
      />
    );
  };

  const confirmationDialogBody = (
    <AgxColumn veryLargeGap extraClasses={'confirmationModalStyle'}>
      <Images.AlertCircleOutline />
      <AgxHeader size={3}>
        Editing {formName} will void current DocuSign envelope
      </AgxHeader>
      <AgxBodyText>
        Current recipients will be notified, you'll be able to generate a new{' '}
        {formName} and send a new DocuSign envelope.
      </AgxBodyText>
      <AgxBodyText>Continue to edit?</AgxBodyText>
      {isMobile && <AgxDivider />}
      <div className={'confirmationButtonContainerStyle'}>
        <AgxButton
          id="voidnedit"
          large
          primary
          text="Void and Edit"
          disabled={disableInputs}
          rightIcon={<Images.EditOutline />}
          onClick={onConfirmEdit}
        />
        <AgxButton
          id="cancel"
          hollow
          large
          text="No, go back"
          disabled={disableInputs}
          rightIcon={<Images.CloseOutline />}
          onClick={() => setShowConfirmationModel(false)}
        />
      </div>
    </AgxColumn>
  );

  return (
    <>
      <AgxRow centered spaceBetween largeGap>
        <ViewDocumentsButton />
        <AgxButton
          key="editSubmission"
          hollow
          large={!isMobile}
          medium={isMobile}
          text="Edit"
          rightIcon={<Images.EditOutline />}
          onClick={onEditAgencyAgreementClick}
          disabled={
            disableInputs ||
            completionState.completionDate !== null ||
            currentTab === Tabs.Archive
          }
          wide={isMobile}
        />
      </AgxRow>

      {showConfirmationModel && (
        <AgxSlideUpModal
          closeButton
          content={confirmationDialogBody}
          desktop={!isMobile}
          onClose={() => setShowConfirmationModel(false)}
        />
      )}
    </>
  );
};
